import React from "react";
import { EducationBanner, EducationBannerProps } from "components/layout/private/EducationBanner";
import links from "lib/links";
import LockedPeriodThumbnail from "public/images/education/LockedPeriodThumbnail.webp";

export const LOCKED_PERIOD_EDUCATION_TEXT = {
  EDUCATION_BANNER_FEATURE: "Locked Period",
  EDUCATION_BANNER_TITLE: "Managing locked periods with Puzzle",
  EDUCATION_BANNER_DESCRIPTION: (
    <>
      <p>Puzzle's locked period functionality enables you to:</p>
      <ul>
        <li>
          Prevent changes to your financial statements and general ledger by locking periods after
          closing your books.
        </li>
        <li>Review new data with dates falling within locked periods.</li>
        <li>Allow admins to unlock periods when adjustments are needed.</li>
      </ul>
    </>
  ),
};

const LockedPeriodEducationBanner = ({
  actionButton,
}: Pick<EducationBannerProps, "actionButton">) => {
  return (
    <EducationBanner
      feature={LOCKED_PERIOD_EDUCATION_TEXT.EDUCATION_BANNER_FEATURE}
      title={LOCKED_PERIOD_EDUCATION_TEXT.EDUCATION_BANNER_TITLE}
      description={LOCKED_PERIOD_EDUCATION_TEXT.EDUCATION_BANNER_DESCRIPTION}
      learnMore={links.education.lockedPeriod.learnMore}
      tutorial={links.education.lockedPeriod.tutorial}
      thumbnail={LockedPeriodThumbnail}
      actionButton={actionButton}
      showCloseButton
    />
  );
};

export default LockedPeriodEducationBanner;
