import React, { useCallback, useMemo, useState } from "react";
import { DateValue, endOfMonth, now, today } from "@internationalized/date";
import { isSameDateValue, parseCalendarMonth, toCalendarMonthString } from "@puzzle/utils";
import { Button, ButtonGroup, DateInput, useToasts } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import type { ActiveCompanyFragment } from "components/companies/graphql.generated";
import { useCompanyDateFormatter } from "components/companies/useCompanyDateFormatter";
import { LockedPeriodInput, LockWarning, MembershipRole } from "graphql/types";
import { WarningDialog } from "./WarningDialog";
import { useGetCompanyPeriodQuery, useSetLockedPeriodMutation } from "./graphql.generated";
import { ActiveCompanyFragmentDoc } from "components/companies/graphql.generated";
import { differenceInDays } from "date-fns/differenceInDays";
import LockedPeriodEducationBanner from "./LockedPeriodEducationBanner";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";

const InfoSection = styled("div", {
  fontSize: "$bodyS",
  fontWeight: "400",
  color: "$gray400",
  padding: "$1h",
  borderColor: "$gray700",
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "$1",
  marginBottom: "$1",
});

const CalendarSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "280px",
});

const TitleStyle = styled("div", {
  color: "$gray200",
  marginBottom: "$1",
  marginTop: "$2",
});

export type Month = string & { _month: never };
export enum LockDayWarning {
  WithinFiveDays,
  UnlockPeriod,
  TransactionWarning,
}
export type AllLockWarning = LockDayWarning | LockWarning;

/**
 * Checks if today is up to 5 days before the end of the requested period.
 * @param period
 * @param timeZone
 * @returns {boolean}
 */
const withinFiveDays = (period: DateValue, timeZone: string) => {
  const lastDayOfPeriod = endOfMonth(period);
  const diff = differenceInDays(lastDayOfPeriod.toDate(timeZone), now(timeZone).toDate());
  return diff >= 0 && diff <= 5;
};

export const LockedPeriod = () => {
  const dateFormatter = useCompanyDateFormatter({
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const { membershipRole, company, timeZone, lockedPeriodDate } = useActiveCompany<true>();
  const { toast } = useToasts();
  const [period, setPeriod] = useState<DateValue | null>(null);
  const [warnings, setWarnings] = useState<AllLockWarning[] | null>(null);
  const open = useMemo(() => Boolean(warnings), [warnings]);
  const [isUnlock, setIsUnlock] = useState(false);
  const [setLockedPeriodMutation, { loading: isSettingLockedPeriod }] =
    useSetLockedPeriodMutation();
  const showEducationBanner = isPosthogFeatureFlagEnabled(FeatureFlag.EducationBanners);
  const companyId = company.id;

  const isAdminMember =
    membershipRole && (["Admin", "Bookkeeper"] as MembershipRole[]).includes(membershipRole);
  const onOpenChange = () => {
    setWarnings(null);
    setIsUnlock(false);
  };

  const { data: potentialPeriod, loading: isGettingLockedPeriod } = useGetCompanyPeriodQuery({
    variables: {
      id: companyId,
      period: period ? toCalendarMonthString(period) : null,
    },
  });
  const initialPeriod = lockedPeriodDate;

  const setLock = useCallback(async () => {
    const input: LockedPeriodInput = {
      companyId,
      period: period ? toCalendarMonthString(period) : null,
    };
    return setLockedPeriodMutation({
      variables: { input },
      update: (cache, { data }) => {
        if (!data?.setLockedPeriod) {
          return;
        }
        const options = {
          fragmentName: "activeCompany",
          fragment: ActiveCompanyFragmentDoc,
        };
        const fragmentId = `Company:${companyId}`;
        const fragment = cache.readFragment<ActiveCompanyFragment>({
          ...options,
          id: fragmentId,
        });
        if (fragment) {
          cache.writeFragment<ActiveCompanyFragment>({
            ...options,
            id: fragmentId,
            data: {
              ...fragment,
              lockedPeriod: { period: period ? toCalendarMonthString(period) : undefined },
              features: {
                ...fragment.features,
              },
            },
          });
        }
      },
    });
  }, [companyId, period, setLockedPeriodMutation]);

  const handleUnlock = () => {
    setWarnings([]);
    setIsUnlock(true);
  };

  const canLock = useMemo(() => potentialPeriod?.company?.canLockPeriod, [potentialPeriod]);

  const checkWarning = useCallback(async () => {
    if (period && canLock?.able) {
      if (canLock.warnings && canLock.warnings.length > 0) {
        setWarnings(canLock.warnings);
      } else if (withinFiveDays(period, timeZone)) {
        // This isn't really possible since the maxDate is end of last month?
        setWarnings([LockDayWarning.WithinFiveDays]);
      } else {
        const result = await setLock();
        const success = result.data?.setLockedPeriod;
        if (success) {
          toast({ message: "Locked period was successfully updated." });
        } else if (result.errors) {
          toast({ message: result.errors[0].message, status: "error" });
        }
      }
    }
  }, [period, canLock, timeZone, setLock, toast]);

  const returnWarning = useMemo(() => {
    return (
      <WarningDialog
        period={period ? toCalendarMonthString(period) : null}
        // Always prefer optimisticResponse (mutation needs to return company)
        onComplete={(period) => setPeriod(period ? parseCalendarMonth(period) : null)}
        warnings={warnings}
        open={open}
        onOpenChange={onOpenChange}
        isUnlock={isUnlock}
      />
    );
  }, [period, warnings, open, isUnlock]);

  return (
    <>
      {showEducationBanner && <LockedPeriodEducationBanner />}
      <InfoSection>
        The locked period stops data from being changed for a specific time. Only admins have
        ability to set or change the locked period.
      </InfoSection>
      {isAdminMember ? (
        <>
          <TitleStyle>Prevent all users from making changes on or before</TitleStyle>
          <CalendarSection>
            <DateInput
              size="small"
              view="month"
              closeOnSelection={false}
              inputReadOnly
              maxDate={endOfMonth(today(timeZone).subtract({ months: 1 }))}
              value={period ? endOfMonth(period) : initialPeriod}
              onChange={(value) => setPeriod(value)}
              footer={
                <ButtonGroup direction="vertical">
                  <Button
                    variant="primary"
                    disabled={
                      Boolean(!period && initialPeriod) ||
                      (period &&
                        initialPeriod &&
                        isSameDateValue(period, initialPeriod, "month")) ||
                      isSettingLockedPeriod ||
                      isGettingLockedPeriod ||
                      !canLock?.able
                    }
                    onClick={checkWarning}
                    css={{ width: "100%", marginBottom: "$1" }}
                  >
                    Lock Period
                  </Button>
                  {initialPeriod && (
                    <Button
                      variant="negative"
                      disabled={isSettingLockedPeriod}
                      onClick={handleUnlock}
                      css={{ width: "100%" }}
                    >
                      Clear locked period
                    </Button>
                  )}
                </ButtonGroup>
              }
            />
            {(period || isUnlock) && returnWarning}
          </CalendarSection>
          {initialPeriod && (
            <Button
              variant="negative"
              disabled={isSettingLockedPeriod}
              onClick={handleUnlock}
              css={{ marginTop: "$2" }}
            >
              Clear locked period
            </Button>
          )}
        </>
      ) : (
        <>
          {initialPeriod ? (
            <TitleStyle>
              All users are prevented from making changes on or before{" "}
              {dateFormatter.format(initialPeriod)}.
            </TitleStyle>
          ) : (
            <TitleStyle>
              Currently, no locked periods have been set. All historical periods can be posted to,
              and all historical transactions can be changed.
            </TitleStyle>
          )}
        </>
      )}
    </>
  );
};
