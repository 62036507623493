import React, { useState } from "react";
import Image, { StaticImageData } from "next/image";
import { PlayCircleFilled } from "@puzzle/icons";
import {
  baseRootStyle,
  playerContainer,
  playButtonStyle,
  thumbnailStyle,
} from "./YouTubePlayer.css";
import { Box } from "@puzzle/ve";

export type YouTubePlayerProps = {
  videoUrl: string;
  customThumbnailUrl?: StaticImageData;
  onPlay?: () => void;
  width?: number;
  height?: number;
  showPlayButton?: boolean;
};

export const YouTubePlayer = ({
  videoUrl,
  customThumbnailUrl,
  onPlay,
  width,
  height,
  showPlayButton = false,
}: YouTubePlayerProps) => {
  const [videoStarted, setVideoStarted] = useState(false);
  const videoId = videoUrl.split("v=")[1];
  const autoplay = customThumbnailUrl ? "1" : "0";

  const handleOnPlay = () => {
    setVideoStarted(true);
    onPlay?.();
  };

  return (
    <Box className={playerContainer} style={{ minWidth: width, height }}>
      {videoStarted || !customThumbnailUrl ? (
        <iframe
          className={baseRootStyle}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&color=white&hl=en&rel=0`}
          allow="autoplay"
          allowFullScreen
        />
      ) : (
        <>
          <Image src={customThumbnailUrl} alt="" className={thumbnailStyle} loading="eager" />
          {showPlayButton && (
            <PlayCircleFilled className={playButtonStyle} onClick={handleOnPlay} />
          )}
        </>
      )}
    </Box>
  );
};
